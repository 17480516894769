import { RadioGroup } from "@headlessui/react"
import { useEffect, useState } from "react"
import { useUtils } from "../store/zustandStates"

const settings = [
  {
    name: "Real Clock",
    description: "Our recommended setting. Shows current time where YOU are",
    mode: "real",
  },
  {
    name: "Bowling Time",
    description: "Great for those times you really need to be on time",
    mode: "bowling",
  },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ")
}

export default function TimeFormatCard() {
  const [selected, setSelected] = useState(settings[0])
  const setTimeMode = useUtils((state) => state.setTimeMode)

  useEffect(() => {
    selected.mode === "real" && setTimeMode(selected.mode)
    selected.mode === "bowling" && setTimeMode(selected.mode)
    // eslint-disable-next-line
  }, [selected])

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <RadioGroup.Label className='sr-only'>Privacy setting</RadioGroup.Label>
      <div className='bg-white rounded-md -space-y-px'>
        {settings.map((setting, settingIdx) => (
          <RadioGroup.Option
            key={setting.name}
            value={setting}
            className={({ checked }) =>
              classNames(
                settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                settingIdx === settings.length - 1
                  ? "rounded-bl-md rounded-br-md"
                  : "",
                checked
                  ? "bg-indigo-50 border-indigo-200 z-10"
                  : "border-gray-200",
                "relative border p-4 flex cursor-pointer focus:outline-none"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? "bg-indigo-600 border-transparent"
                      : "bg-white border-gray-300",
                    active ? "ring-2 ring-offset-2 ring-indigo-500" : "",
                    "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
                  )}
                  aria-hidden='true'
                >
                  <span className='rounded-full bg-white w-1.5 h-1.5' />
                </span>
                <div className='ml-3 flex flex-col'>
                  <RadioGroup.Label
                    as='span'
                    className={classNames(
                      checked ? "text-indigo-900" : "text-gray-900",
                      "block text-sm font-medium"
                    )}
                  >
                    {setting.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as='span'
                    className={classNames(
                      checked ? "text-indigo-700" : "text-gray-500",
                      "block text-sm"
                    )}
                  >
                    {setting.description}
                  </RadioGroup.Description>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
