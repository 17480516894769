import { shaderMaterial } from "@react-three/drei";
import { extend } from "@react-three/fiber";

const LoadingMaterial = shaderMaterial(
  {
    time: 0,
  },
  `
    varying vec2 vUv;
    varying vec3 Normal;
    void main() {
      vUv = uv;
      Normal = normal;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  `
  varying vec2 vUv;
  varying vec3 Normal;
  uniform float time;
  
    void main() {

      float kd = 0.15;
      float ka = 0.6;
      vec3 lightPosition = vec3(-1.0, 1.0, 0.0);
      vec3 diffuse = kd * vec3(max(dot(lightPosition, Normal), 0.0));

      gl_FragColor.rgba = vec4(diffuse + vec3(ka), 1.0);
    }
  `
);

extend({ LoadingMaterial });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      loadingMaterial: any;
    }
  }
}
