import { MeshProps, useFrame, useLoader, useThree } from "@react-three/fiber";
import React, { FC, useRef, useState } from "react";
import { TextureLoader, Vector3 } from "three";
import "../materials/EarthMaterial";
import earth_stencils from "./earth_stencils.jpg";
import earth_diffuse from "./earth_texture_diffuse.jpg";

interface Props extends MeshProps {
  sunPosition: Vector3;
}

const Earth: FC<Props> = (props) => {
  const { sunPosition } = props;
  const { camera } = useThree();

  const mesh = useRef<any>();
  const [, setHover] = useState(false);
  const [active, setActive] = useState(false);

  const [diffuseTexture, stencilTexture] = useLoader(TextureLoader, [
    earth_diffuse,
    earth_stencils,
  ]);

  useFrame((state, delta) => {
    if (mesh.current) {
      mesh.current.material.time += 0.01;
      if (camera?.position) {
        mesh.current.material.cameraPos = new Vector3(
          camera.position.x,
          camera.position.y,
          camera.position.z
        );
      }
    }
  });

  return (
    <mesh
      {...props}
      ref={mesh}
      scale={1}
      onClick={(event) => setActive(!active)}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}
    >
      <sphereGeometry args={[1.6, 164, 164]} />
      <earthMaterial
        cameraPos={new Vector3()}
        sunPosition={sunPosition}
        diffuseTexture={diffuseTexture}
        stencilTexture={stencilTexture}
      />
    </mesh>
  );
};

export default Earth;
