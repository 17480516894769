import { shaderMaterial } from "@react-three/drei";
import { extend } from "@react-three/fiber";
import { Texture, Vector3 } from "three";
import { glslHelpFunctions } from "./glslHelpFunctions";
import { perlinNoise } from "./perlinNoise";
import { worleyNoise } from "./worleyNoise";

const EarthMaterial = shaderMaterial(
  {
    time: 0,
    cameraPos: new Vector3(),
    sunPosition: new Vector3(),
    diffuseTexture: new Texture(),
    stencilTexture: new Texture(),
  },
  `
    varying vec2 vUv;
    varying vec3 Normal;
    varying vec3 Position;
    void main() {
      vUv = uv;
      Normal = normal;
      Position = position;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  `
    ${glslHelpFunctions}
    varying vec2 vUv;
    varying vec3 Normal;
    varying vec3 Position;
    
    uniform float time;
    uniform vec3 color;
    uniform vec3 cameraPos;
    uniform vec3 sunPosition;
    uniform sampler2D diffuseTexture;
    uniform sampler2D stencilTexture;
    
    ${worleyNoise}
    ${perlinNoise}
   
   void main() {
    float ka = 0.02;
    float kd = 0.3;
    float ks = 0.6;
    float alpha = 80.0;
    
    vec4 diffuseColor = 1.2 * texture2D(diffuseTexture, vUv.xy);
    vec4 stencil = texture2D(stencilTexture, vUv.xy);

    float specularStencil = stencil.x;
    vec4 nightColor = vec4(vec3(texture2D(stencilTexture, vUv.xy).g), 1.0);
    float cloudXMovement = vUv.x + time * 0.01;
    vec4 cloudColor = vec4(vec3(texture2D(stencilTexture, vec2(fract(cloudXMovement), fract(vUv.y + 0.04 * sin(0.2 * time)))).b), 1.0);
    vec3 cameraPosition = normalize(cameraPos);

    float waterHeight = getBumpmap(Position, time);
    vec3 waterNormal = calculateNormal(Position, time);

    vec3 N = waterNormal;
    vec3 V = normalize(cameraPosition);
    vec3 L = normalize(sunPosition);
    vec3 diffuse = kd * vec3(max(dot(L, N), 0.0));
    
    vec3 R = normalize(2.0 * dot(L, N) * N - L);
    
    float gamma = 8.0;
    float lambda = 1.0 - dot(R, V);
    float beta = alpha / gamma;
    // vec3 specular = vec3(ks * pow(max(dot(R, V), 0.0), alpha));
    vec3 specular = vec3(ks * pow(max(0.0, 1.0 - beta * lambda), gamma));

    vec3 diffuseLand = 1.15 * (length(diffuse) + ka) * diffuseColor.xyz * (1.0 - specularStencil);
    vec3 diffuseWater = (length(diffuse) + ka) * specularStencil * ((0.2 * (1.0 - abs(Position.y)) + 0.20 + 0.2 * waterHeight) * vec3(0.2, 0.7, 0.9 ));
    vec3 specularWater = specular * specularStencil * vec3(1.0, 0.9, 0.65);

    
    vec4 rotatedCamera = rotationMatrix(vec3(0.0, 1.0, 0.0), 3.1415 / 2.0) * vec4(cameraPos, 1.0);
    float latitudeLineStencil = clamp(4.0*(1.0 - 45.0 * length(dot(vec3(rotatedCamera.x, 0.0, rotatedCamera.z), vec3(Normal.x, 0.0, Normal.z)))), 0.0, 1.0);
    float cloudIntensity = 1.1 * (4.0 * diffuse.x + 0.3) * length(cloudColor) * (0.9 * pow(fract(cloudXMovement) * fract(1.0 - cloudXMovement), 1.1) + 0.02);
    float fog = 0.6 * clamp(1.9 * max(1.0 - dot(Normal, cameraPosition), 0.0), 0.0, 1.0);

    vec3 blackWhite = vec3(length(diffuseLand + diffuseWater + specularWater));
    vec3 color = vec3(diffuseLand + diffuseWater + specularWater);
    vec3 waterAndLand = mix(color, blackWhite, 0.10) + vec3(cloudIntensity) + 0.3 * vec3(latitudeLineStencil, 0.3 * latitudeLineStencil, 0.0);
    vec3 fogAdded = waterAndLand + fog;
    vec3 nightAdded = fogAdded + 0.2 * (1.0 - length(diffuseLand)) * vec3(0.2, 0.4, 0.8) + 0.7 * max(1.0 - 5.0 * length(diffuseLand), 0.0) * nightColor.xyz * vec3(1.0, 0.8, 0.5);

    
    gl_FragColor.rgba = vec4(nightAdded, 1.0);
    // gl_FragColor.rgba = vec4(diffuseLand * 3.3, 1.0);
    // gl_FragColor.rgba = vec4(stencil.xyz, 1.0);
    // gl_FragColor.rgba = vec4(diffuse.x, 0.0, specular.b, 1.0);
    
  }
  `
);

extend({ EarthMaterial });
declare global {
  namespace JSX {
    interface IntrinsicElements {
      earthMaterial: any;
    }
  }
}
