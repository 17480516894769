import { MeshProps, useFrame, useThree } from "@react-three/fiber";
import React, { FC, useRef } from "react";
import { BackSide, Vector3 } from "three";
import "../materials/AtmosphereMaterial";

interface Props extends MeshProps {
  sunPosition: Vector3;
}

const Atmosphere: FC<Props> = (props) => {
  const { sunPosition } = props;

  const { camera } = useThree();
  const mesh = useRef<any>();
  useFrame((state, delta) => {
    if (mesh.current) {
      mesh.current.material.time += 0.1;
      if (camera?.position) {
        mesh.current.material.cameraPos = new Vector3(
          camera.position.x,
          camera.position.y,
          camera.position.z
        );
      }
    }
  });

  return (
    <mesh {...props} ref={mesh}>
      <sphereGeometry args={[1.75, 24, 24]} />
      <atmosphereMaterial
        side={BackSide}
        cameraPos={new Vector3()}
        sunPosition={sunPosition}
      />
    </mesh>
  );
};

export default Atmosphere;
