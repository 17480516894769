import { addMilliseconds } from "date-fns";
import React, { FC, useCallback } from "react";
import { useRealClock } from "../hooks/useRealClock";
import { useUtils } from "../store/zustandStates";

interface Props {
  className?: string;
  longitude: number;
  latitude: number;
  cameraLongitude?: number;
}

const Clock: FC<Props> = ({
  className,
  longitude,
  latitude,
  cameraLongitude,
}) => {
  const timeMode = useUtils((state) => state.timeMode);
  const { realClockTime, queryPositionsTime } = useRealClock({
    mode: timeMode,
    longitude,
    latitude,
  });

  const calculateCameraDate = useCallback(() => {
    const longDiff = (cameraLongitude || 0) - longitude;
    const hourDiff = (24 * longDiff) / 360;
    const calculatedDate = addMilliseconds(new Date(), hourDiff * 3600000);
    const position = queryPositionsTime([
      { latitude, longitude: cameraLongitude ?? 0, date: calculatedDate },
    ])[0];

    return `${`${position.hours < 10 ? 0 : ""}${position.hours}:${
      position.minutes < 10 ? 0 : ""
    }${position.minutes}`}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realClockTime, cameraLongitude]);

  if (!realClockTime) {
    return <div />;
  }

  return (
    <div
      className={`flex flex-row justify-center font-mono text-gray-900 ${className}`}
    >
      <div className="relative inline-block px-6">
        <div className="flex flex-row justify-center ">
          <h1 className="flex justify-center items-center text-5xl font-bold">
            {/* {format(realClockTime, "p", { locale: sv })} */}
            {`${realClockTime.hours < 10 ? 0 : ""}${realClockTime.hours}:${
              realClockTime.minutes < 10 ? 0 : ""
            }${realClockTime.minutes}`}
          </h1>
          <p className="absolute right-0 font-bold">{`${
            realClockTime.seconds < 10 ? 0 : ""
          }${realClockTime.seconds}`}</p>
        </div>

        <h1
          className={
            "flex justify-center text-yellow-600 bg-gray-900 transition-opacity font-semibold rounded-md"
          }
        >
          {cameraLongitude
            ? calculateCameraDate()
            : `${realClockTime.hours < 10 ? 0 : ""}${realClockTime.hours}:${
                realClockTime.minutes < 10 ? 0 : ""
              }${realClockTime.minutes}`}
        </h1>
      </div>
    </div>
  );
};

export default Clock;
