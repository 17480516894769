import { MeshProps, useFrame } from "@react-three/fiber";
import React, { useRef } from "react";
import "../materials/LoadingMaterial";

const LoadingSphere = (props: MeshProps) => {
  const mesh = useRef<any>();
  useFrame((state, delta) => {
    if (mesh.current) {
      mesh.current.material.time += 0.1;
    }
  });

  return (
    <mesh {...props} ref={mesh}>
      <sphereGeometry args={[1.6, 24, 24]} />
      <loadingMaterial />
    </mesh>
  );
};

export default LoadingSphere;
