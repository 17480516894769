/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { Link } from "react-router-dom";
import { usePreviousDistinct } from "react-use";
import { useCustomerId, usePricelist } from "../hooks/useShop";
const stripePromise = loadStripe(
  "pk_test_51IoYbvB38DS9lmDjVG6r0gsW0VFSGXWOS856DmnmHdK6F4cJFxTehzrmifiL8WpUCqexPDDz1rBtfF9vM3YZ3DhG00h3e2pfBk"
);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement: any = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
};
const SettingsPayment: React.FC = () => {
  const prices = usePricelist();
  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 max-w-3xl">
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
      <section aria-labelledby="plan_heading" className="flex flex-row">
        {prices?.map((price) => {
          return (
            <div
              id={price.id}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden first:mr-4 flex-1"
            >
              <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                <div className="flex justify-center">
                  <h3
                    className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600"
                    id="tier-standard"
                  >
                    {price.product.name}
                  </h3>
                </div>
                <div className="mt-4 flex items-center justify-center text-4xl font-extrabold text-center w-full">
                  {price.unit_amount / 100} {price.currency} / mo
                </div>
              </div>
              <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                <ul className="space-y-4">
                  {price.product.description.split(", ").map((listitem) => (
                    <li key={price.product.id} className="flex items-start">
                      <div className="flex-shrink-0">
                        <svg
                          className="h-6 w-6 text-green-500"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <p className="ml-3 text-base text-gray-700">{listitem}</p>
                    </li>
                  ))}
                </ul>
                <div className="rounded-md shadow">
                  <Link
                    to={{
                      pathname: "/checkout",
                      state: { productId: price.product.id },
                    }}
                    className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                  >
                    Start plan
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </section>

      <section aria-labelledby="billing_history_heading">
        <div className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden">
          <div className="px-4 sm:px-6">
            <h2
              id="billing_history_heading"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Billing history
            </h2>
          </div>
          <div className="mt-6 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden border-t border-gray-200">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Description
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Amount
                        </th>

                        <th
                          scope="col"
                          className="relative px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <span className="sr-only">View receipt</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <time dateTime="2020-01-01">1/1/2020</time>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Business Plan - Annual Billing
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          CA$109.00
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <a
                            href="#"
                            className="text-orange-600 hover:text-orange-900"
                          >
                            View receipt
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SettingsPayment;
