import React from "react";
import { BiCog } from "react-icons/bi";
import { Link } from "react-router-dom";
import google from "../assets/btn_google_signin_dark_focus_web@2x.png";
import { useAuth } from "../hooks/useAuth";
import Spinner from "./Spinner";

const TopBar: React.FC = () => {
  const { signIn, user, loading } = useAuth();

  return (
    <div className="absolute top-2 left-2 text-gray-700">
      {loading ? (
        <Spinner />
      ) : !user ? (
        <img
          onClick={signIn}
          alt="Sign in with google"
          className="cursor-pointer w-32"
          src={google}
        />
      ) : (
        <div className="flex flex-row items-center justify-center  text-lg">
          <span className="mx-2 ">{user.displayName}</span>
          <Link to="/settings">
            <BiCog className="cursor-pointer" />
          </Link>
        </div>
      )}
    </div>
  );
};

export default TopBar;
