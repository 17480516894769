import create, { State } from "zustand"

interface UtilsState extends State {
  timeMode: "real" | "bowling"
  setTimeMode: (timeMode: "real" | "bowling") => void
}

export const useUtils = create<UtilsState>((set) => ({
  timeMode: "real",
  setTimeMode: (timeMode: "real" | "bowling") => set({ timeMode }),
}))
