import firebase from "firebase/app";
import { useState } from "react";
import { useAsync } from "react-use";
import { useAuth } from "./useAuth";
interface Price {
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  id: string;
  livemode: boolean;
  lookup_key: string;
  metadata: any;
  nickname: string;
  object: string;
  product: {
    active: boolean;
    attributes: any[];
    created: number;
    description: string;
    id: string;
    images: any[];
    livemode: boolean;
    metadata: any;
    name: string;
    object: string;
    package_dimensions: any;
    shippable: boolean;
    statement_descriptor: any;
    type: string;
    unit_label: string;
    updated: number;
    url: string;
  };
  recurring: {
    aggregate_usage: any;
    interval: string;
    interval_count: number;
    trial_period_days: any;
    usage_type: string;
  };
  tiers_mode: any;
  transform_quantity: any;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
}

export function usePricelist() {
  const [prices, setPrices] = useState<Price[]>();
  useAsync(async () => {
    const resp = await fetch(
      "https://europe-west3-real-clock-8ebd3.cloudfunctions.net/config"
    );
    const data: { prices: Price[] } = await resp.json();
    setPrices(
      data.prices.sort((a, b) => (a.unit_amount < b.unit_amount ? -1 : 1))
    );
  }, []);
  return prices;
}

export function useCustomerId() {
  const [customerId, setCustomerId] = useState<string>();
  const { user } = useAuth();
  useAsync(async () => {
    if (user) {
      const userData = await firebase
        .firestore()
        .doc(`users/${user?.uid}`)
        .get();
      const customerId = userData?.data()?.customerId;
      if (customerId) {
        console.log("HERE?");
        setCustomerId(userData?.data()?.customerId);
      } else {
        console.log("here");
        const resp = await fetch(
          "https://europe-west3-real-clock-8ebd3.cloudfunctions.net/createCustomer"
        );
        const newCustomer = (await resp.json())?.customer;
        console.log(newCustomer);
        await firebase
          .firestore()
          .doc(`users/${user?.uid}`)
          .set({ customerId: newCustomer.id }, { merge: true });
        setCustomerId(newCustomer.id);
      }
    }
  }, [user]);
  return customerId;
}
