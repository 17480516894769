import { addMilliseconds, addMinutes } from "date-fns";
import { useEffect, useState } from "react";

interface RealClockOptions {
  longitude: number;
  latitude: number;
  mode?: "real" | "bowling";
}

const getDateTimeInGreenwich = (): Date => {
  const currentDate = new Date();
  const dateGMT = addMinutes(currentDate, currentDate.getTimezoneOffset());
  return dateGMT;
};

export const useRealClock = ({
  mode = "real",
  longitude,
  latitude,
}: RealClockOptions) => {
  const [realClockTime, setRealClockTime] =
    useState<{
      hours: number;
      minutes: number;
      seconds: number;
    }>();

  useEffect(() => {
    const hour = longitude ? (longitude / 365) * 24 : 0;
    const dateGMT = getDateTimeInGreenwich();
    const withMilliseconds = addMilliseconds(dateGMT, hour * 3600000);
    setTimeout(() => setRealClockTime(modeConverter(withMilliseconds)), 1000);

    // eslint-disable-next-line
  }, [realClockTime, longitude]);

  const modeConverter = (time: Date) => {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();

    if (mode === "bowling") {
      const bowlingConstant = 60 / 55;

      let bowlingHours = Math.floor(hours * bowlingConstant);
      let bowlingHourResidual = (hours * bowlingConstant) % 1;
      let bowlingMinutes = minutes * bowlingConstant + bowlingHourResidual * 60;
      while (bowlingMinutes >= 55) {
        bowlingHours += 1;
        bowlingMinutes -= 55;
      }

      const bowlingMinutesResidual = bowlingMinutes % 1;
      bowlingMinutes = Math.floor(bowlingMinutes);

      let bowlingSeconds = Math.floor(seconds + bowlingMinutesResidual * 60);
      while (bowlingSeconds >= 60) {
        bowlingMinutes += 1;
        bowlingSeconds -= 60;
      }

      if (bowlingMinutes >= 55) {
        bowlingHours += 1;
        bowlingMinutes -= 55;
      }

      return {
        hours: bowlingHours,
        minutes: bowlingMinutes,
        seconds: bowlingSeconds,
      };
    } else {
      return { hours, minutes, seconds };
    }
  };

  const queryPositionsTime = (
    positions: { latitude: number; longitude: number; date: Date }[]
  ) => {
    const realTimes: { hours: number; minutes: number; seconds: number }[] = [];
    for (let i = 0; i < positions.length; i++) {
      const hour = positions[i].longitude
        ? (positions[i].longitude / 365) * 24
        : 0;

      const dateGMT = getDateTimeInGreenwich();

      const withMilliseconds = addMilliseconds(dateGMT, hour * 3600000);
      realTimes.push(modeConverter(withMilliseconds));
    }

    return realTimes;
  };

  return { realClockTime, queryPositionsTime };
};
