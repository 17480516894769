import { Canvas } from "@react-three/fiber";
import React, { FC, Suspense } from "react";
import { calcSunPosition } from "../../utils/calcSunPosition";
import Atmosphere from "./Atmosphere";
import Earth from "./Earth";
import LoadingSphere from "./LoadingSphere";
import OrbitController from "./OrbitController";

interface Props {
  className?: string;
  longitude?: number;
  latitude?: number;
  onCameraRotation?: (angle: number) => void;
}

const EarthScene: FC<Props> = ({
  longitude,
  latitude,
  onCameraRotation,
  className,
}) => {
  const today = new Date();
  today.setHours(today.getHours() + 0);
  const sunPosition = calcSunPosition(today);
  return (
    <div className={className}>
      <Canvas>
        <OrbitController
          onCameraRotation={onCameraRotation}
          longitude={longitude}
          latitude={latitude}
        />
        <Suspense fallback={<LoadingSphere />}>
          <Earth sunPosition={sunPosition} />
          <Atmosphere sunPosition={sunPosition} />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default EarthScene;
