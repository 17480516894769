import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import React, { useState } from "react";
import { useGeolocation } from "react-use";
import Clock from "./components/Clock";
import TopBar from "./components/TopBar";
import EarthScene from "./fiber/components/EarthScene";

const firebaseConfig = {
  apiKey: "AIzaSyCSa3auDvfGzXpm-NiChj-CVb-VKWM5nsc",
  authDomain: "real-clock-8ebd3.firebaseapp.com",
  projectId: "real-clock-8ebd3",
  storageBucket: "real-clock-8ebd3.appspot.com",
  messagingSenderId: "988768165933",
  appId: "1:988768165933:web:1f3a806b806883b5c858a4",
  measurementId: "G-59D149N9T8",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

const App = () => {
  const [cameraLongitude, setCameraLongitude] = useState<number | undefined>();
  const handleCameraRotation = (angle: number) =>
    setCameraLongitude((360 * (angle - Math.PI / 2)) / (2.0 * Math.PI));
  const state = useGeolocation({ enableHighAccuracy: false, maximumAge: 0 });
  if (state.loading || !state?.longitude || !state?.latitude) {
    return <div />;
  }

  const longitude = state.longitude;
  const latitude = state.latitude;

  return (
    <div className="flex justify-center items-center flex-col w-full h-full bg-gradient-to-t from-gray-400 via-blue-100 to-gray-500 min-h-screen">
      {/* <div className="absolute top-0">{cameraLongitude}</div> */}
      <div className="flex justify-center items-center flex-col w-full h-full bg-gradient-to-t from-gray-400 via-blue-100 to-gray-500">
        <Clock
          className="absolute top-0 p-12 pointer-events-none w-full h-full z-10"
          longitude={longitude}
          latitude={latitude}
          cameraLongitude={cameraLongitude}
        />
        {longitude && (
          <EarthScene
            longitude={longitude}
            latitude={latitude}
            onCameraRotation={handleCameraRotation}
            className="flex-1 absolute  w-full h-full"
          />
        )}
        <div className="flex justify-between absolute bottom-0 left-0 mb-4 w-full px-4 opacity-50 text-xs">
          <p>{latitude}</p> <p>{longitude}</p>
        </div>
      </div>
      <TopBar />
    </div>
  );
};

export default App;
