import React from "react";
import { useLocation } from "react-router-dom";

const Checkout: React.FC = () => {
  const location = useLocation();
  console.log(location.state);
  return <div />;
};

export default Checkout;
