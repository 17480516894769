import React, { useEffect } from "react";
import { TiHome } from "react-icons/ti";
import { useHistory } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import SettingsPayment from "./SettingsPayment";
import Spinner from "./Spinner";
import TimeFormatCard from "./TimeFormatCard";

const Settings: React.FC = () => {
  const { user, loading, signOut } = useAuth();
  let history = useHistory();

  useEffect(() => {
    if (!loading && !user) {
      history.push("/");
    }
  }, [user, loading, history, signOut]);

  const handleClick = () => {
    history.push("/");
  };

  return (
    <div className="w-full min-h-screen bg-gray-100 flex-col flex justify-center items-center pb-16">
      {loading ? (
        <span className="text-3xl">
          <Spinner />
        </span>
      ) : (
        <div>
          <div className="rounded-lg bg-white overflow-hidden shadow my-4 w-full">
            <h2 className="sr-only" id="profile-overview-title">
              Profile Overview
            </h2>
            <div className="relative bg-white p-6">
              <TiHome
                className="absolute left-2 top-2 h-4 w-4 text-gray-500 hover:text-gray-400 cursor-pointer"
                onClick={handleClick}
              />
              <div className="sm:flex sm:items-center sm:justify-between">
                <div className="sm:flex sm:space-x-5">
                  <div className="flex-shrink-0">
                    <img
                      className="mx-auto h-20 w-20 rounded-full"
                      src={user?.photoURL ?? ""}
                      alt=""
                    />
                  </div>
                  <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                    <p className="text-sm font-medium text-gray-600">
                      Welcome back,
                    </p>
                    <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                      {user?.displayName}
                    </p>
                    <p className="text-sm font-medium text-gray-600">
                      {user?.email}
                    </p>
                  </div>
                </div>
                <div className="mt-5 flex justify-center sm:mt-0">
                  <button
                    onClick={signOut}
                    className="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Sign out
                  </button>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
              <div className="px-6 py-5 text-sm font-medium text-center">
                <span className="text-gray-900 mr-1">8 saved</span>
                <span className="text-gray-600 underline">real</span>{" "}
                <span className="text-gray-600">clocks</span>
              </div>

              <div className="px-6 py-5 text-sm font-medium text-center">
                <span className="text-gray-900 mr-1">4</span>
                <span className="text-gray-600">Nightwatches</span>
              </div>
            </div>
          </div>
          <div className="space-y-5">
            <SettingsPayment />
            <TimeFormatCard />
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
