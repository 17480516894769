import React, { FC } from "react";
import { ImEarth } from "react-icons/im";
interface Props {
  className?: string;
}

const Spinner: FC<Props> = ({ className = "" }) => {
  return <ImEarth className={`animate-spin ${className}`} />;
};

export default Spinner;
