import firebase from "firebase/app";
import { useMemo, useState } from "react";
export function useAuth() {
  const [user, setUser] = useState<firebase.User>();
  const provider = useMemo(() => new firebase.auth.GoogleAuthProvider(), []);
  const [loading, setLoading] = useState(true);
  const signIn = () => firebase.auth().signInWithRedirect(provider);
  const signOut = () => firebase.auth().signOut();
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(undefined);
    }
    setLoading(false);
  });
  return { signIn, user, signOut, loading };
}
