import { extend, MeshProps, useFrame, useThree } from "@react-three/fiber";
import React, { FC, useEffect, useState } from "react";
import { Vector3 } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
extend({ OrbitControls });

interface Props extends MeshProps {
  onCameraRotation?: (angle: number) => void;
  longitude?: number;
  latitude?: number;
}

const OrbitController: FC<Props> = (props) => {
  const { onCameraRotation, longitude, latitude } = props;
  const [initialized, setInitialized] = useState(false);

  const {
    camera,
    gl: { domElement },
  } = useThree();

  useFrame((state, delta) => {
    if (camera?.rotation.z) {
      const angle = Math.atan2(camera.position.x, camera.position.z);
      onCameraRotation && onCameraRotation(angle);
    }
  });

  useEffect(() => {
    if (!initialized && camera && longitude) {
      const angleInRadians = (2 * Math.PI * (longitude + 90)) / 360;
      camera.position.x = 5.0 * Math.sin(angleInRadians);
      camera.position.z = 5.0 * Math.cos(angleInRadians);
      camera.position.y = 1.49;
      camera.lookAt(new Vector3(0, 0, 0));
      setInitialized(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longitude, latitude]);

  return (
    <orbitControls
      args={[camera, domElement]}
      // enableZoom={false}
      minDistance={2.1}
      maxDistance={7.0}
      zoomSpeed={0.4}
      rotateSpeed={0.7}
      enablePan={false}
      maxPolarAngle={Math.PI / 2 - 0.3}
      minPolarAngle={Math.PI / 2 - 0.3}
      // maxPolarAngle={Math.PI / 2 + 0.5}
      // minPolarAngle={Math.PI / 2 - 0.5}
    />
  );
};

export default OrbitController;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      orbitControls: any;
    }
  }
}
