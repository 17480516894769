import { shaderMaterial } from "@react-three/drei";
import { extend } from "@react-three/fiber";
import { Vector3 } from "three";

const AtmosphereMaterial = shaderMaterial(
  {
    cameraPos: new Vector3(),
    sunPosition: new Vector3(),
    time: 0,
  },
  `
    varying vec2 vUv;
    varying vec3 Normal;
    varying vec3 Position;
    void main() {
      vUv = uv;
      Normal = normal;
      Position = position;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  `
  varying vec2 vUv;
  varying vec3 Normal;
  varying vec3 Position;
    uniform float time;
  uniform vec3 cameraPos;
  uniform vec3 sunPosition;
  
    void main() {

      float kd = 0.15;
      float ka = 0.6;
      vec3 lightPosition = vec3(-1.0, 1.0, 0.0);
      vec3 diffuse = kd * vec3(max(dot(lightPosition, Normal), 0.0));

      vec4 atmosphere = 3.9 * (1.0 - dot(Normal, cameraPos)) * vec4(0.7, 0.8, 0.9, 1.0) / (1.0 + 0.8 * length(cameraPos));
      gl_FragColor.rgba = atmosphere;
    }
  `
);

extend({ AtmosphereMaterial });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      atmosphereMaterial: any;
    }
  }
}
