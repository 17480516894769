import { Euler, Vector3 } from "three";

// Source of formula
// https://astronomy.stackexchange.com/questions/20560/how-to-calculate-the-position-of-the-sun-in-long-lat

export const calcSunPosition = (date?: Date): Vector3 => {
  const now = date || new Date();

  // The boilerplate: fiddling with dates
  const soy = new Date(now.getFullYear(), 0, 0).getTime();
  const eoy = new Date(now.getFullYear() + 1, 0, 0).getTime();
  const nows = now.getTime();
  const poy = (nows - soy) / (eoy - soy);

  const secs =
    now.getUTCMilliseconds() / 1e3 +
    now.getUTCSeconds() +
    60 * (now.getUTCMinutes() + 60 * now.getUTCHours());
  const pod = secs / 86400; // leap secs? nah.

  // The actual magic
  const latitude = (-pod + 0.5) * Math.PI * 2;
  const longitude = Math.sin((poy - 0.22) * Math.PI * 2) * 0.41;

  const angle = new Euler(0, latitude, longitude, "YZX");
  const unitVector = new Vector3(1, 0, 0);
  return unitVector.applyEuler(angle);
};
